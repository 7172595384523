import styled from "styled-components";
import { default as NewTextField } from "@mui/material/TextField";

export const Container = styled.div`
	display: block;
	max-width: 1920px;
	margin: 0 auto;
	width: 100%;
	@media ${(props) => props.theme.breakpoints.md} {
		width: 100%;
	}
`;

export const TextField = styled.p`
	font-family: ${(props) => props.fontFamily};
	font-weight: ${(props) => props.fontWeight};
	font-size: ${(props) => props.fontSize};
	color: #2351a3;
`;
export const Button = styled.div`
	background: #f26522;
	border-radius: 0.5rem;
	width: max-content;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 2rem;
	&:hover {
		cursor: pointer;
	}
`;

export const Seperator = styled.div`
	width: 3.8rem;
	border-bottom: 4px solid #f26522;
`;
export const Text = styled.p`
	font-family: ${(props) => props.fontFamily};
	font-weight: ${(props) => props.fontWeight};
	font-size: ${(props) => props.fontSize};
	color: #9f9f9f;
`;

export const MuiTextField = ({ ...rest }) => {
	return (
		<NewTextField
			sx={{
				fontSize: { md: "18px", xs: "12px" },
				margin: { md: "8px 0", xs: "8px 0" },
			}}
			InputProps={{
				sx: {
					"& > fieldset": {
						border: { md: "2px solid #5F6368", xs: "1px solid #5F6368" },
						borderRadius: { md: "8px", xs: "4px" },
						fontSize: { md: "18px", xs: "12px" },
					},
					lineHeight: { md: "1.9rem", xs: "1.3rem" },
				},
			}}
			inputProps={{ sx: { fontSize: { md: "1.4rem", xs: "1rem" } } }}
			InputLabelProps={{ sx: { fontSize: { md: "14px", xs: "10px" } } }}
			fullWidth
			size="small"
			variant="outlined"
			{...rest}
		/>
	);
};
