import React from "react";
import axios from "axios";
import Theme from "./styles/theme";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "./styles/GlobalStyles";
const DemoCreation = React.lazy(() => import("./pages/demo-creation"));
const WiingyProfiles = React.lazy(() => import("./pages/wiingy-profiles"));
const TSMAvailability = React.lazy(() => import("./pages/tsm-availability"));
const TutorContractTable = React.lazy(() => import("./components/tutor/TutorContractTable"));
const SendcustomNotification = React.lazy(() => import("./pages/sendcustomNotification"));
const Insession = React.lazy(() => import("./pages/insession"));
const TestAPP = React.lazy(() => import("./pages/test"));
const Supply = React.lazy(() => import("./pages/supply"));
const Dashboard = React.lazy(() => import("./pages/dashboard"));

axios.defaults.baseURL = "https://adminserver.wiingy.com/"
axios.defaults.headers.common['x-request-origin'] = "Wiingy/AdminDashboard";

function App() {
	return (
		<Theme>
			<Container>
				<Router>
					<Routes>
						<Route exact path="/dashboard" element={
							<React.Suspense fallback={<>Loading...</>}>
								<Dashboard />
							</React.Suspense>
						} />
						<Route exact path="/supply" element={
							<React.Suspense fallback={<>Loading...</>}>
								<Supply />
							</React.Suspense>
						} />
						<Route exact path="/test" element={
							<React.Suspense fallback={<>Loading...</>}>
								<TestAPP />
							</React.Suspense>
						} />
						<Route exact path="/insession" element={
							<React.Suspense fallback={<>Loading...</>}>
								<Insession />
							</React.Suspense>
						} />
						<Route exact path="/send-custom-notification" element={
							<React.Suspense fallback={<>Loading...</>}>
								<SendcustomNotification />
							</React.Suspense>
						} />
						<Route exact path="/tsm-availability" element={
							<React.Suspense fallback={<>Loading...</>}>
								<TSMAvailability />
							</React.Suspense>
						} />
						<Route exact path="/demoCreation" element={
							<React.Suspense fallback={<>Loading...</>}>
								<DemoCreation />
							</React.Suspense>
						} />
						<Route exact path="/tutor-listing/*" element={
							<React.Suspense fallback={<>Loading...</>}>
								<WiingyProfiles />
							</React.Suspense>
						} />
						<Route exact path="/tutor-contract" element={
							<React.Suspense fallback={<>Loading...</>}>
								<TutorContractTable />
							</React.Suspense>
						} />
					</Routes>
				</Router>
			</Container>
		</Theme>
	);
}

export default App;
